import React from 'react'
import './web.css';

import 'react-image-gallery/styles/css/image-gallery.css';

import silver_token from "../../assets/silver_token.gif"

const s_token = [silver_token]

const Web = () => {

    const redirectToExternalUrl = () => {
        window.location.href = 'https://intrinsic-images.xyz/Web_Shop';
    };

    return (

        <div className='web_background'>
            {
                <div>
                    <div className='web_title_redirect'>

                        <h1>Intrinsic S&I Web Shop</h1>
                        <button type='button' className='button3' onClick={() => redirectToExternalUrl()}>Web Shop</button>
                        <div className='div_text'>Obtain Accessories to preform Intrinsic image sets</div>
                        <div className='image_text_row_coin'>
                            <img src={silver_token} alt="Gallery" />
                        </div>
                    </div>

                </div>
            }
        </div>
    )
}

export default Web
